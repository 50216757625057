import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MessageContainer from './MessageContainer';
import '../styles/HistoricoMensagens.css';
import config from '../config';
import historicoImage from '../assets/historicoImage.png';
const apiUrl = config.apiUrl;

const HistoricoMensagens = () => {
  const [atendentes, setAtendentes] = useState([]);
  const [usuariosBot, setUsuariosBot] = useState([]);
  const [usuariosBotCampanha, setUsuariosBotCampanha] = useState([]);
  const [mensagensBot, setMensagensBot] = useState([]);
  const [mensagensBotCampanha, setMensagensBotCampanha] = useState([]);
  const [mensagensAtendente, setMensagensAtendente] = useState({});
  const [numerosAtendente, setNumerosAtendente] = useState({});
  const [selectedUsuarioBot, setSelectedUsuarioBot] = useState('');
  const [selectedUsuarioBotCampanha, setSelectedUsuarioBotCampanha] = useState('');
  const [selectedUsuarioAtendente, setSelectedUsuarioAtendente] = useState({});
  const [nomeRobo, setNomeRobo] = useState('bot');
  const [periodos, setPeriodos] = useState([]);
  const [selectedPeriodo, setSelectedPeriodo] = useState('');
  const [selectedPeriodoCampanha, setSelectedPeriodoCampanha] = useState('');
  const [selectedDia, setSelectedDia] = useState('');
  const [selectedDiaCampanha, setSelectedDiaCampanha] = useState('');
  const [diasDoMes, setDiasDoMes] = useState([]);

  const formatarPeriodo = (periodo) => {
    if (!periodo) return "";
    const [mes, ano] = periodo.split('/');
    const meses = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];
    const mesIndex = parseInt(mes, 10) - 1;
    return `${meses[mesIndex]}/${ano}`;
  };

  const getDiasDoMes = (periodo) => {
    if (!periodo) return [];
    const [mes, ano] = periodo.split('/').map(Number);
    const totalDias = new Date(ano, mes, 0).getDate();
    const hoje = new Date();
    if (ano === hoje.getFullYear() && mes === hoje.getMonth() + 1) {
      return Array.from({ length: hoje.getDate() }, (_, i) => i + 1);
    }
    return Array.from({ length: totalDias }, (_, i) => i + 1);
  };

  useEffect(() => {
    const fetchParametros = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${apiUrl}/parametros`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const parametro = response.data.find(p => p.parametro === 'Nome do robô');
        if (parametro) setNomeRobo(parametro.valor);
      } catch (error) {
        console.error('Error fetching parametro:', error);
      }
    };
    fetchParametros();
  }, []);

  useEffect(() => {
    const fetchPeriodos = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${apiUrl}/historico/periodos`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setPeriodos(response.data);
      } catch (error) {
        console.error('Error fetching periodos:', error);
      }
    };
    fetchPeriodos();
  }, []);

  useEffect(() => {
    setDiasDoMes(getDiasDoMes(selectedPeriodo));
    setSelectedDia('');
  }, [selectedPeriodo]);

  useEffect(() => {
    setDiasDoMes(getDiasDoMes(selectedPeriodoCampanha));
    setSelectedDiaCampanha('');
  }, [selectedPeriodoCampanha]);

  useEffect(() => {
    if (selectedPeriodoCampanha) {
      const fetchUsuariosBotCampanhaPorPeriodo = async () => {
        const token = localStorage.getItem('token');
        try {
          const response = await axios.get(`${apiUrl}/historico/numeros/bot/por-periodo/camp`, {
            headers: { Authorization: `Bearer ${token}` },
            params: { periodo: selectedPeriodoCampanha, dia: selectedDiaCampanha || undefined }
          });
          setUsuariosBotCampanha(response.data);
        } catch (error) {
          console.error('Error fetching usuariosBotCampanha:', error);
        }
      };
      fetchUsuariosBotCampanhaPorPeriodo();
    }
  }, [selectedPeriodoCampanha, selectedDiaCampanha]);

  useEffect(() => {
    const fetchAtendentes = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${apiUrl}/atendente`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setAtendentes(response.data);
      } catch (error) {
        console.error('Error fetching atendentes:', error);
      }
    };
    fetchAtendentes();
  }, []);

  useEffect(() => {
    if (selectedPeriodo) {
      const fetchUsuariosBotPorPeriodo = async () => {
        const token = localStorage.getItem('token');
        try {
          const response = await axios.get(`${apiUrl}/historico/numeros/bot/por-periodo`, {
            headers: { Authorization: `Bearer ${token}` },
            params: { periodo: selectedPeriodo, dia: selectedDia || undefined }
          });
          setUsuariosBot(response.data);
        } catch (error) {
          console.error('Error fetching usuariosBot:', error);
        }
      };
      fetchUsuariosBotPorPeriodo();
    }
  }, [selectedPeriodo, selectedDia]);

  useEffect(() => {
    if (selectedUsuarioBot) {
      const fetchMensagensBot = async () => {
        const token = localStorage.getItem('token');
        try {
          const response = await axios.get(`${apiUrl}/historico/bot?numero=${encodeURIComponent(selectedUsuarioBot)}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setMensagensBot(response.data);
        } catch (error) {
          console.error('Error fetching mensagens bot:', error);
        }
      };
      fetchMensagensBot();
    }
  }, [selectedUsuarioBot]);

  useEffect(() => {
    if (selectedUsuarioBotCampanha) {
      const fetchMensagensBotCampanha = async () => {
        const token = localStorage.getItem('token');
        try {
          const response = await axios.get(`${apiUrl}/historico/botcampanhas?numero=${encodeURIComponent(selectedUsuarioBotCampanha)}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setMensagensBotCampanha(response.data);
        } catch (error) {
          console.error('Error fetching mensagens bot campanha:', error);
        }
      };
      fetchMensagensBotCampanha();
    }
  }, [selectedUsuarioBotCampanha]);

  const fetchNumerosAtendente = async atendente => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${apiUrl}/historico/numeros/user?numero=${encodeURIComponent(atendente.telefone)}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setNumerosAtendente(prevState => ({ ...prevState, [atendente.telefone]: response.data }));
    } catch (error) {
      console.error('Error fetching numeros atendente:', error);
    }
  };

  const fetchMensagensAtendente = async (atendente, usuario) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${apiUrl}/historico/user?numero=${encodeURIComponent(atendente.telefone)}&numeroUser=${encodeURIComponent(usuario)}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setMensagensAtendente(prevState => ({ ...prevState, [atendente.telefone]: response.data }));
    } catch (error) {
      console.error('Error fetching mensagens atendente:', error);
    }
  };

  useEffect(() => {
    atendentes.forEach(atendente => {
      fetchNumerosAtendente(atendente);
    });
  }, [atendentes]);

  return (
    <div>
      <h1>Histórico de mensagens</h1>
      <div className="historico-image-container">
        <img src={historicoImage} alt="historicoImage" className="historico-image" />
      </div>
      <div className="historico-mensagens-container">
        <div className="historico-mensagens-item">
          <h2>Mensagens com {nomeRobo}</h2>
          <select onChange={e => setSelectedPeriodo(e.target.value)}>
            <option value="">Selecione um período</option>
            {periodos.map(periodo => (
              <option key={periodo} value={periodo}>
                {formatarPeriodo(periodo)}
              </option>
            ))}
          </select>
          <select onChange={e => setSelectedDia(e.target.value)} disabled={!selectedPeriodo}>
            <option value="">Selecione um dia (opcional)</option>
            {diasDoMes.map(dia => (
              <option key={dia} value={dia}>{dia}</option>
            ))}
          </select>
          <select onChange={e => setSelectedUsuarioBot(e.target.value)} disabled={!selectedPeriodo}>
            <option value="">Selecione um usuário</option>
            {usuariosBot.map(usuario => (
              <option key={usuario.numero} value={usuario.numero}>
                {usuario.numero}
              </option>
            ))}
          </select>
          {selectedUsuarioBot && (
            <MessageContainer
              title={`Mensagens com ${nomeRobo} (${selectedUsuarioBot})`}
              mensagens={mensagensBot}
              className="historico-mensagens-bot"
              nomeRobo={nomeRobo}
              atendenteNumero=""
            />
          )}
        </div>

        <div className="historico-mensagens-item">
          <h2>Mensagens de Campanha com {nomeRobo}</h2>
          <select onChange={e => setSelectedPeriodoCampanha(e.target.value)}>
            <option value="">Selecione um período</option>
            {periodos.map(periodo => (
              <option key={periodo} value={periodo}>{formatarPeriodo(periodo)}</option>
            ))}
          </select>
          <select onChange={e => setSelectedDiaCampanha(e.target.value)} disabled={!selectedPeriodoCampanha}>
            <option value="">Selecione um dia (opcional)</option>
            {diasDoMes.map(dia => (
              <option key={dia} value={dia}>{dia}</option>
            ))}
          </select>
          <select onChange={e => setSelectedUsuarioBotCampanha(e.target.value)} disabled={!selectedPeriodoCampanha}>
            <option value="">Selecione um usuário</option>
            {usuariosBotCampanha.map(usuario => (
              <option key={usuario.numero} value={usuario.numero}>
                {usuario.numero}
              </option>
            ))}
          </select>
          {selectedUsuarioBotCampanha && (
            <MessageContainer
              title={`Mensagens de Campanha com ${nomeRobo} (${selectedUsuarioBotCampanha})`}
              mensagens={mensagensBotCampanha}
              className="historico-mensagens-bot-campanha"
              nomeRobo={nomeRobo}
              atendenteNumero=""
            />
          )}
        </div>

        {atendentes.map(atendente => (
          <div key={atendente.telefone} className="historico-mensagens-item">
            <h2>Atendente: {atendente.nome}</h2>
            <select
              onChange={e => {
                const usuario = e.target.value;
                setSelectedUsuarioAtendente(prevState => ({ ...prevState, [atendente.telefone]: usuario }));
                fetchMensagensAtendente(atendente, usuario);
              }}
            >
              <option value="">Selecione um usuário</option>
              {numerosAtendente[atendente.telefone]?.map((usuario, index) => (
                <option key={index} value={usuario.numero}>
                  {usuario.numero}
                </option>
              ))}
            </select>
            {mensagensAtendente[atendente.telefone] && (
              <MessageContainer
                title={`Mensagens com Atendente (${atendente.nome}) e Usuário (${selectedUsuarioAtendente[atendente.telefone]})`}
                mensagens={mensagensAtendente[atendente.telefone]}
                className="historico-mensagens-atendente"
                nomeRobo={nomeRobo}
                atendenteNumero={atendente.telefone}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HistoricoMensagens;