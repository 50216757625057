import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/ManageMensagens.css';
import { toast } from 'react-toastify';
import config from '../config';
import msgImage from '../assets/messageImage.png'
const apiUrl = config.apiUrl;
const ManageMensagens = () => {
  const [mensagens, setMensagens] = useState([]);
  const [editingMensagem, setEditingMensagem] = useState(null);
  const [novaMensagem, setNovaMensagem] = useState('');


  // Função para retornar a observação baseada no título
  const getObservacao = (titulo) => {
    switch (titulo) {
      case "Entrada do Usuário":
        return "Mensagem inicial do chat. Segue para coleta de CPF/CNPJ.";
      case "Solicitação de CPF ou CNPJ":
        return "Mostrada após escolher 'Cliente'. Se válido, segue para 'Seleção de Módulo do Cliente'. Caso contrário, exibe 'CPF ou CNPJ não Encontrado'.";
      case "Seleção de Módulo do Cliente":
        return "Exibida após validar CPF/CNPJ. Direciona para 'Opções Financeiro Menu' ou 'Opções Comercial Menu'.";
      case "Opções Financeiro Menu":
        return "Aparece após escolher 'Financeiro'. Pode levar a 'Solicitação de Número de Título' ou 'Solicitação de Número de Nota Fiscal'.";
      case "Solicitação de Número de Título":
        return "Mostrada ao selecionar consulta de títulos. Segue para 'Listagem de Títulos' ou 'Status do Pedido'.";
      case "Solicitação de Número de Nota Fiscal":
        return "Exibida ao solicitar 2ª via de NF. Segue para 'Envio de Nota Fiscal em PDF' se encontrada.";
      case "Envio de Nota Fiscal em PDF":
        return "Aparece após fornecer um número válido. Retorna ao 'Opções Financeiro Menu'.";
      case "Opções Comercial Menu":
        return "Exibida após escolher 'Comercial'. Direciona para 'Solicitação de Número de Pedido' ou 'Redirecionamento para Televendas'.";
      case "Solicitação de Número de Pedido":
        return "Mostrada ao consultar status de pedido. Segue para 'Status do Pedido'.";
      case "Listagem de Pedidos":
        return "Aparece após definir um período para pedidos. Retorna ao 'Opções Comercial Menu'.";
      case "Período de Pedidos":
        return "Solicita intervalo de datas antes da 'Listagem de Pedidos'.";
      case "Período de Títulos Financeiros":
        return "Solicita intervalo de datas antes da 'Listagem de Títulos'.";
      case "Redirecionamento para Televendas":
        return "Aparece ao escolher televendas. Pode levar a 'Atendentes Indisponíveis'.";
      case "Listagem de Títulos":
        return "Exibida após definir um período para títulos financeiros. Retorna ao 'Opções Financeiro Menu'.";
      case "Status do Pedido":
        return "Mostrada após fornecer número de pedido. Retorna ao 'Opções Comercial Menu'.";
      case "Saudação para Atendimento":
        return "Exibida após validar CPF/CNPJ para atendimento humano. Direciona para módulos de atendimento.";
      case "Atendimento Financeiro":
        return "Aparece ao escolher 'Financeiro'. Segue para 'Encaminhamento Financeiro' ou 'Atendentes Indisponíveis'.";
      case "Atendimento Comercial":
        return "Exibida ao escolher 'Comercial'. Segue para 'Encaminhamento Comercial' ou 'Atendentes Indisponíveis'.";
      case "Atendimento SAC":
        return "Aparece ao escolher 'SAC'. Segue para 'Encaminhamento SAC' ou 'Atendentes Indisponíveis'.";
      case "Solicitação de CPF ou CNPJ atendente":
        return "Mostrada ao selecionar atendimento humano. Se válido, segue para 'Saudação para Atendimento'.";
      case "Encaminhamento Financeiro":
        return "Mostrada ao confirmar encaminhamento financeiro.";
      case "Encaminhamento Comercial":
        return "Exibida ao confirmar encaminhamento comercial.";
      case "Encaminhamento SAC":
        return "Aparece ao confirmar encaminhamento SAC.";
      case "Atendentes Indisponíveis":
        return "Mostrada quando não há atendentes. Retorna à 'Entrada do Usuário'.";
      case "Solicitação de CNPJ do Fornecedor":
        return "Aparece ao selecionar 'Fornecedor'. Se válido, segue para 'Menu do Fornecedor'.";
      case "Menu do Fornecedor":
        return "Exibido após validar CNPJ. Direciona para opções específicas.";
      case "Solicitação de CNPJ do Representante":
        return "Aparece ao selecionar 'Representante'. Se válido, segue para 'Menu do Representante'.";
      case "Menu do Representante":
        return "Exibido após validar CNPJ. Direciona para opções específicas.";
      case "Confirmar Dados do Cliente":
        return "Mostrada se o cliente já tem CPF registrado. Segue para 'Seleção de Módulo do Cliente' se confirmado.";
      case "Confirmar Dados do Fornecedor":
        return "Exibida se fornecedor já tem CNPJ registrado. Segue para 'Menu do Fornecedor' se confirmado.";
      case "Confirmar Dados do Representante":
        return "Aparece se representante já tem CNPJ registrado. Segue para 'Menu do Representante' se confirmado.";
      case "Data Inicial para Consulta":
        return "Parte do 'Período de Pedidos' ou 'Período de Títulos Financeiros'. Segue para 'Data Final para Consulta'.";
      case "Data Final para Consulta":
        return "Segunda etapa da seleção de período. Prossegue para listagem de registros.";
      case "CPF ou CNPJ não Encontrado":
        return "Mostrada quando CPF/CNPJ fornecido é inválido.";
      case "Erro na Data Informada":
        return "Aparece se as datas fornecidas forem inválidas.";
      case "Sobre a Empresa":
        return "Exibida ao selecionar 'Sobre a Empresa'. Segue para 'Detalhes da Empresa'.";
      case "Detalhes da Empresa":
        return "Aparece após escolher mais informações em 'Sobre a Empresa'.";
      case "Local de Venda":
        return "Mostrada ao selecionar 'Local de Venda'. Retorna ao menu inicial.";
      case "Saudação do GPT":
        return "Ativada ao escolher 'GPT' no menu inicial.";
      case "Serviço Indisponível":
        return "Mostrada quando um serviço não está ativo.";
      case "Em Construção":
        return "Aparece para funcionalidades em desenvolvimento.";
      case "Sem Resposta":
        return "Mensagem de inatividade no atendimento humanizado.";
      case "Mensagem de Erro":
        return "Exibida para entradas inválidas.";
      case "Mensagem de Saída":
        return "Aparece ao digitar 'sair'. Encerra a conversa.";
      case "Despedida por Inatividade":
        return "Enviada automaticamente após tempo de inatividade.";
      case "Envio de Nota Fiscal Campanhas":
        return "Mensagem de campanha de envio de nota fiscal em PDF ao cliente.";
      case "Envio de Boleto Campanhas":
        return "Mensagem de campanha de envio de boleto em PDF ao cliente.";
      default:
        return "Nenhuma observação disponível.";
    }
  };


  useEffect(() => {
    const ordemMensagens = [
      "Entrada do Usuário",
      "Solicitação de CPF ou CNPJ",
      "Seleção de Módulo do Cliente",
      "Opções Financeiro Menu",
      "Solicitação de Número de Título",
      "Solicitação de Número de Nota Fiscal",
      "Envio de Nota Fiscal em PDF",
      "Opções Comercial Menu",
      "Solicitação de Número de Pedido",
      "Listagem de Pedidos",
      "Período de Pedidos",
      "Período de Títulos Financeiros",
      "Redirecionamento para Televendas",
      "Listagem de Títulos",
      "Status do Pedido",
      "Saudação para Atendimento",
      "Atendimento Financeiro",
      "Atendimento Comercial",
      "Atendimento SAC",
      "Solicitação de CPF ou CNPJ atendente",
      "Encaminhamento Financeiro",
      "Encaminhamento Comercial",
      "Encaminhamento SAC",
      "Atendentes Indisponíveis",
      "Solicitação de CNPJ do Fornecedor",
      "Menu do Fornecedor",
      "Solicitação de CNPJ do Representante",
      "Menu do Representante",
      "Confirmar Dados do Cliente",
      "Confirmar Dados do Fornecedor",
      "Confirmar Dados do Representante",
      "Data Inicial para Consulta",
      "Data Final para Consulta",
      "CPF ou CNPJ não Encontrado",
      "Erro na Data Informada",
      "Sobre a Empresa",
      "Detalhes da Empresa",
      "Local de Venda",
      "Saudação do GPT",
      "Serviço Indisponível",
      "Em Construção",
      "Sem Resposta",
      "Mensagem de Erro",
      "Mensagem de Saída",
      "Despedida por Inatividade"
    ];

    const fetchMensagens = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${apiUrl}/mensagens`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        const sortedMensagens = response.data.sort((a, b) => {
          return ordemMensagens.indexOf(a.titulo) - ordemMensagens.indexOf(b.titulo);
        });

        setMensagens(sortedMensagens);
      } catch (error) {
        console.error('Erro ao buscar mensagens:', error);
        toast.error('Erro ao buscar mensagens');
        window.location.reload();
      }
    };
    fetchMensagens();
  }, []);

  const handleEditClick = (mensagem) => {
    setEditingMensagem(mensagem);
    setNovaMensagem(mensagem.mensagem);
  };

  const handleCancelClick = () => {
    setEditingMensagem(null);
    setNovaMensagem('');
  };

  const handleSaveClick = async (mensagemId) => {
    const token = localStorage.getItem('token');
    try {
      await axios.put(`${apiUrl}/mensagens`, {
        mensagemId,
        novaMensagem,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setMensagens(mensagens.map(mensagem =>
        mensagem.id === mensagemId ? { ...mensagem, mensagem: novaMensagem } : mensagem
      ));
      setEditingMensagem(null);
      setNovaMensagem('');
      toast.success('Mensagem atualizada com sucesso');
    } catch (error) {
      console.error('Erro ao atualizar mensagem:', error);
      toast.error('Erro ao atualizar mensagem');
    }
  };

  return (
    <div className="manage-mensagens-container">
      <h2>Gerenciar Mensagens do Chatbot</h2>
      <img src={msgImage} alt="msg-image" className="msg-image" />
      <h5 >Observação: O "\n" é utilizado para pular linha no Whatsapp</h5>
      <h5 className="important-text">
        NÃO pode ser alterado a escrita que estiver entre chaves, como exemplo {"{NUM_PDV}"} caso contrário o robô não encontrará as variáveis.
      </h5>
      <div className="mensagens-list">
        {mensagens.map(mensagem => (
          <div key={mensagem.id} className="mensagem-item">
            <h3>{mensagem.titulo}</h3>
            {editingMensagem?.id === mensagem.id ? (
              <div className="edit-area">
                <textarea
                  value={novaMensagem}
                  onChange={(e) => setNovaMensagem(e.target.value)}
                />
                <button className="save-button" onClick={() => handleSaveClick(mensagem.id)}>Salvar</button>
                <button className="cancel-button" onClick={handleCancelClick}>Cancelar</button>
              </div>
            ) : (
              <div className="mensagem-content">
                <p>{mensagem.mensagem}</p>
                <div className="observacao-display">
                  <h4>Observação:</h4>
                  <p>{getObservacao(mensagem.titulo)}</p>
                </div>
                <button className="edit-button" onClick={() => handleEditClick(mensagem)}>Editar</button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ManageMensagens;
