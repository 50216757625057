import React, { useState } from "react";
import "../styles/AgileAgentsTable.css";
import agileOneAsset from "../assets/agileOneAsset.png";

const agents = [
  { name: "Agente Impulso 🚀", area: "Comercial", description: "Automatiza propostas, prevê fechamento de negócios e gerencia follow-ups no CRM." },
  { name: "Agente Abastece 📦", area: "Suprimentos", description: "Prevê necessidade de compra, gera pedidos automaticamente no ERP e otimiza estoques." },
  { name: "Agente Produz 🏭", area: "Manufatura", description: "Detecta gargalos na produção, ajusta cronogramas e recomenda otimizações." },
  { name: "Agente Fluxo 💰", area: "Financeiro", description: "Monitora fluxo de caixa, alerta sobre inadimplências e sugere ajustes financeiros." },
  { name: "Agente Equilibra 💡", area: "Custos", description: "Analisa rentabilidade, sugere precificação e otimiza alocação de recursos." },
  { name: "Agente SAGE 🤖", area: "Inteligência Generativa", description: "Atua como o cérebro analítico dos Agile Robots, aprendendo com interações e otimizando respostas." }
];

const AgileAgentsTable = () => {
  const [activeStatus, setActiveStatus] = useState(
    agents.reduce((acc, agent) => ({ ...acc, [agent.name]: false }), {})
  );

  const toggleActivation = (name) => {
    setActiveStatus((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  return (
    <div className="agile-agents-container">
      <div className="agile-agents-wrapper">
        <img src={agileOneAsset} alt="Agile One Asset" />
        <h2>📍 Agile ONE - Agentes Inteligentes de IA para ERP</h2>
        <table className="agile-table">
          <thead>
            <tr>
              <th className="agent-column">🔷 Agente</th>
              <th>📊 Área</th>
              <th>⚡ O que ele faz?</th>
              <th>Ativo</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {agents.map((agent) => (
              <tr key={agent.name} className="mensagem-item">
                <td>{agent.name}</td>
                <td>{agent.area}</td>
                <td>{agent.description}</td>
                <td>{activeStatus[agent.name] ? "Sim" : "Não"}</td>
                <td>
                  <button
                    className={activeStatus[agent.name] ? "cancel-button" : "save-button"}
                    onClick={() => toggleActivation(agent.name)}
                  >
                    {activeStatus[agent.name] ? "Desativar" : "Ativar"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AgileAgentsTable;